@import '../../global.scss';
@import '../../responsive/breakpoints.scss';


.intro {
  width: 100%;
  display: flex;
  


  .left {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include media("<=bigPhone") {
      flex: 0;
    }
  }
  .right {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    

    .wrapper{
      display: inline-block;
        // background: rgb(138, 128, 128);
      height: auto;
      margin-top: 0px;
      margin-bottom: 10%;
      @include media("<=bigPhone") {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: calc(100vh - 70px);
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        padding: 5%;
    }
    @include media(">=bigPhone", "<=tablet") {
      height: calc(100vh - 70px);
    }
      
      .heythere{
        color: $titleColor;
        font-size: 1.5em;
        margin: 0px;
        // background: hotpink;
        @include media("<=bigPhone") {
          font-size: 2em;
        }
        @include media(">=bigPhone", "<=tablet") {
          width: auto;
          font-size: 1.4em;
           position: absolute;
           top: 20%;
        }
      }

      h1{
        font-size: 4em;
        // background: green;
        margin-top: .5em;
        margin-bottom: 0px;
        color: $titleColor;
        @include media("<=bigPhone") {
        font-size: 3em;
          width: auto;
          position: absolute;
          bottom: 25%;
        }
        @include media(">=bigPhone", "<=tablet") {
          width: auto;
          position: absolute;
          bottom: 45%;
        }
        
      }

      .frontend{
        font-weight: 500;
        font-size: 2em;
        margin: 0px;
        color: $buttonsColor;
        @include media("<=bigPhone") {
          width: auto;
          font-size: 1.5em;
           position: absolute;
           bottom: 20%;

          // top: 50%;
          // right: 0;
        }
        @include media(">=bigPhone", "<=tablet") {
          width: auto;
          font-size: 1.8em;
           position: absolute;
           bottom: 35%;
        }
        span {
          color : $specialColor;
        }
      }
      .projects {
        position: absolute;
        bottom: 10%;
        color: $textColor;
      }
      .arrow-icon {
        font-size: 1.8em;
        position: absolute;
        bottom: 4%;
        color: $textColor;
      }
    
    }
  }
}