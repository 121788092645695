@import '../../global.scss';
@import '../../responsive/breakpoints.scss';


.contact-form {
  margin-bottom: 100px;
  width: 25vw;
  @include media("<=bigPhone") {
    width: 90vw;
    margin-bottom: 5vh;

  }
  @include media(">=bigPhone", "<=tablet") {
    width: 55vw;
    margin-bottom: 5vh;
  }
  @include media(">tablet","<LGdesktop") {
    width: 35vw;
  }

  .wrapper {
    width:100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 1%;
    // background: rgb(238, 160, 160);
    @include media("<=bigPhone") {
      justify-content: flex-start;
    height: auto;
      
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      //background: cornflowerblue;
      @include media("<=bigPhone") {
        margin: 3vh 0 0 0;
      }
      button {
        @include button;
        font-size: 1em;
        width: 20%;
        @include media("<=bigPhone") {
          width: 40%;
          }
      }
      .submitState {
        color: $specialColor;
        margin-left: 5px;
        
      }
    }
    textarea {
      resize: none;
      width: 90%;
      border-radius: 5px;
      background: $techStackColor;
      border: 1px solid $textColor;
      color: $titleColor;
    
    }
    .name, .email, .message {
      display: flex;
      // background: cornflowerblue;
      height: auto;
      width: 100%;
      margin: 0 0 20px 0;
      //padding: 5px;
      flex-direction: column;
      align-items: flex-start;
      color: $textColor;
      
      @include media("<=bigPhone") {
        margin: 3vh 0 0 0;
      }
      .errors {
        color: $specialColor;
      }
      input {
        background: $techStackColor;
        color: $titleColor;
        width: 90%;
        height: 2.5em;
        border-radius: 5px;
        border: 1px solid $textColor;
        padding-left: .5em;
        font-size: 1em;
        font-weight: 400;
        input:focus {
          border:2px solid $techStackColor !important;
        }
        
      }
      h3 {
        font-weight: 500;
        margin: 0 0 .5em 0;
      }
      h4 {
        color: red;
        font-size: 1em;
        margin: 0;
        font-weight: 400;
      }
    }
  }
}
