$mainColor : #919DAF;
$specialColor: #3ac9b0;
$buttonsColor : #6752ff;
$techStackColor : #474747;
$buttonsColorLighter : #aba1f1;
$textColor : #adadad;
$subTitleColor : #cacaca;
$titleColor: #FEFFFE;
$backgroundColor : #1e1e1e;
$navbarColor : #1B1A1A;


//BLOBS COLORS
$blobColor: rgb(102, 215, 243);



@mixin sectionTitle {
  color: $titleColor;
  font-size: 2em;
  font-weight: 600;
}

@mixin projectTitle {
  color: $titleColor;
  font-size: 1.5em;
  font-weight: 600;
}

@mixin boxShadow {
  -webkit-box-shadow: 0px 2px 8px -1px rgb(24, 24, 24); 
  box-shadow: 0px 2px 8px -1px rgb(19, 19, 19);

}

@mixin button {
  
    border-radius: 5px;
    background: $buttonsColor;
    border: none;
    padding: 10px;
    color: white;
    backface-visibility: hidden;
    transition-duration: .3s;
    
    &:hover {
      cursor: pointer;
      background-color: $buttonsColorLighter;
      transition-duration: .3s;
    }

}

@mixin webglIndividualBg {
  // position: absolute;
  // background-color: #aba1f1;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
 z-index: 0;

}