@import '../src/global.scss';
@import '/src/responsive/breakpoints.scss';

.app {
  scroll-behavior: smooth;
  height: 100vh;
  position: relative;
  background-color: $backgroundColor;
  // overflow-x: hidden;
  // overflow-y: auto;

  .menuOverlay{
    position: fixed;
    left : 0;
    top : 0;
    background-color: rgb(0, 0, 0);
    opacity: 0.9;
    height: 100vh;
    width: 100vw;
    z-index: 20;
    transition: opacity 0.5s ease-in;
    
    .menuContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      // background-color: rgb(17, 93, 112);
      height: 100%;
      .closeContainer{
        justify-content: flex-end;
        align-items: flex-end;
        box-sizing: border-box;
        padding: 1.95rem 1.45rem;
        // background-color: rgb(17, 93, 112);
        flex-grow: 1;
        transition: 0.5s ease-in;
        overflow: hidden;

        span {
          font-size: 1.9rem;
          font-weight: 600;
          text-decoration: none;
          color: $mainColor;
          margin-bottom: 20px;
          }
      }

      .navsContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      box-sizing: border-box;
      padding: 1.95rem 1.45rem;
      height: 100%;
      transition: 0.5s ease-in;

      // background-color: rgb(114, 176, 192);

          a {
        font-size: 1.7rem;
        font-weight: 600;
        text-decoration: none;
        color: $mainColor;
        margin-bottom: 20px;
        }
      }
      
    }
  }

  .webgl-background {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
   // z-index: 0;

  }



    .sections {
      width: 100%;
      height: calc(100vh - 70px);
      position: relative;
      top: 70px;
      scroll-snap-type: y mandatory;
      //scrollbar-width: none;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        //display: none;
      }
      

       > *{
          width: 100%;
          min-height: calc(100vh - 70px);
          height: auto;

          @include media("<=bigPhone") {
            height: auto;
          }
          @include media(">=bigPhone", "<=tablet") {
            height: auto;
      
          }
    
          
       }
       
       
    }
}