@import '../../global.scss';
@import '../../responsive/breakpoints.scss';

.contact {
//  background: lightcyan;
  position : relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media("<=bigPhone") {
    height: auto;
  }

  .webglBg {
     @include webglIndividualBg;
  }

  h2 {
    z-index: 2;

    @include sectionTitle;
    @include media("<=bigPhone") {
      font-size: 1.5em;
      margin-bottom: 10%;
    }
    .coloredspan{
      color: $specialColor;
    }
  }
  .wrapper {
    position: relative;
    z-index: 2;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  //  background: rgb(146, 135, 128);
   @include media("<=bigPhone") {
    width: 90%;
    min-height: calc(90vh - 70px);
    align-items: center;
    flex-direction: column;
  }
  @include media(">=bigPhone", "<=tablet") {
    width: 90%;
    min-height: calc(100vh - 70px);
  }
  @include media(">tablet","<LGdesktop") {
    width: 70%;
    // margin-bottom: 10%;
  }
    .iconswrapper {
      // height: 450px;
      // width: 150px;
      //background-color: cadetblue;
      // left: 0;
      // position: absolute;
      display: flex;
      width: 50%;
      position: static;

      // flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      box-sizing: border-box;
      padding: 1%;
      @include media("<=bigPhone") {
        width: 100%;
        height: auto;
        flex-direction: row;
        position: static;
        // background-color: aquamarine;
      }
      
      .iconcontainer {
        z-index: 5;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        background: $navbarColor;
        box-shadow: 0 2px 4px rgb(3, 3, 3);
        border-radius: 5px;
        cursor: pointer;
        @include media("<=bigPhone") {
          width: 50px;
          height: 50px;
        }
        .btn-icon {
          font-size: 3rem;
          color: $textColor;
          @include media("<=bigPhone") {
            font-size: 2rem;

          }
        }
      }

    }
  }
}