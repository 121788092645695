@import '../../global.scss';
@import '../../responsive/breakpoints.scss';


.projects {
  //background: rgb(175, 111, 111);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
  @include media("<=bigPhone") {
    height: auto;
  }
  @include media(">=bigPhone", "<=tablet") {
    height: auto;

  }
  

  
  .wrapper {
    z-index: 2;
    margin: 0;
    height: 90%;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    // background: darkgoldenrod;
    box-sizing: border-box;
    padding: 1%;
    margin: 0;

    @include media("<=bigPhone") {
      width: 90%;
      margin-bottom: 20px;
      min-height: calc(100vh - 70px);
    overflow: hidden;


    }
    @include media(">=bigPhone", "<=tablet") {
      width: 80%;
      min-height: calc(100vh - 70px);
      margin-bottom: 20%;
    }
    @include media(">tablet","<LGdesktop") {
      width: 60%;
      margin-bottom: 10%;
    }
    

    .project-title {
      @include projectTitle;
      margin: 0 0 1em 0;
      padding: 0;
      //background: blue;
      @include media("<=bigPhone") {
        margin: 25% 0 1em 0;

      }
    }
    
    .preview-img {
      @include boxShadow;
      border-radius: 14px;
      width: 100%;
      height: 55%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      background-color: $navbarColor;
      @include media("<=bigPhone") {
        height: 30vh;
  
      }
     
      .project-img {
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .preview-video {
      @include boxShadow;
      border-radius: 14px;
      width: 100%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      background-color: $navbarColor;
      @include media("<=bigPhone") {
        min-height: 30vh;
  
      }
      .project-video {
        object-fit: cover;
        height: 100%;
        overflow: hidden;
      }
    }
    .tech-stack {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      //background-color: bisque;
      width: 100%;
      height: auto;
      margin-top: 2%;
      flex-wrap: wrap;
      box-sizing: border-box;
      @include media("<=bigPhone") {
        justify-content: space-between;
        margin : 10px 0 10px 0;

      }
      .stackDiv{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 2em 2px 0;
        color: $titleColor;
        background: $techStackColor;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 8px 20px;
          @include media("<=bigPhone") {
            margin : 0 0 2px 0;
            padding: 4px 10px;
          }
          @include media(">=bigPhone", "<=tablet") {
            padding: 6px 18px;

          }
        span {
          font-size: .8em;
          @include media("<=bigPhone") {
          font-size: .6em;
          }
          @include media(">=bigPhone", "<=tablet") {
            font-size: .7em;
          }
        }
      }
      

    }
    .buttons {
      margin-top: 2%;
      //background: brown;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      @include media("<=bigPhone") {
        width: 250px;
        margin : 20px 0 20px 0;

      }
      button {
        @include button;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-weight: 600;
        width: 45%;
        @include media("<=bigPhone") {
          font-size: 0.8em;
        }
        .btn-icon {
          //margin-left: 5px;
          font-size: 1.5em;
          //background: red;
          @include media("<=bigPhone") {
            font-size: 1.2em;
          }
        }
        

      }
    }
    .description {
      margin-top: 2%;
      color: $textColor;
      @include media("<=bigPhone") {
        font-size: .8em;
      }
      @include media(">=bigPhone", "<=tablet") {
        font-size: .9em;

      }
      
    }

  }
}