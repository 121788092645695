@import '../../global.scss';
@import '../../responsive/breakpoints.scss';


.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .webglBg {
    @include webglIndividualBg;
 }


  h2 {
    z-index: 2;

    @include sectionTitle;
  }
  .wrapper {
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 35%;
    height: auto;
    @include media("<=bigPhone") {
      width: 90%;
      min-height: calc(50vh - 70px);
      justify-content: flex-start;
    }
    @include media(">=bigPhone", "<=tablet") {
      width: 80%;
      min-height: calc(100vh - 70px);
    }
    
    

    .description {
      font-size: 1em;
      text-align: center;
      word-break: break-word;
      color : $textColor;
    }
  }
}
