@import '../../global.scss';
@import '../../responsive/breakpoints.scss';

.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background-color: beige;

  .webglBg {
    @include webglIndividualBg;
 }

  h2 {
    z-index: 2;
    @include sectionTitle;  
    
  }
  .container {

    //  background: rgb(182, 182, 182);
    display: flex;
    justify-content: space-between;
  // align-items: center;
    flex-direction: column;
    width: 40%;
    min-height: calc(80vh - 70px);
    margin-top: 1%;
    
    @include media("<=bigPhone") {
      width: 90%;
      min-height: calc(70vh - 70px);
    }
    @include media(">=bigPhone", "<=tablet") {
      width: 80%;
      min-height: calc(100vh - 70px);
    }
    @include media(">tablet","<LGdesktop") {
      width: 60%;
    }


    .topWrapper {
      flex:1;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 2;

    .subtitle {
      color: $subTitleColor;
      z-index: 2;
      
    }


    .wrapper {
      z-index: 2;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width:100%;
      // height: 50%;
      margin: 0;
      // background: rgb(117, 117, 117);
      @include media("<=bigPhone") {
        margin-bottom: 20%;
      }
      
      .html,.css,.javascript,.react {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        background: $navbarColor;
        box-shadow: 0 2px 4px rgb(3, 3, 3);
        border-radius: 5px;
        width: 23.5%;
        // height: 90%;
        box-sizing: border-box;
        margin: 5px 0 5px 0;
        z-index: 2;
        padding: 2% 0;
        @include media("<=bigPhone") {
          height: 90px;
        }

        h3 {
          font-size: 1rem;
          margin: 0 0 0 0;
          color: $textColor;
          @include media("<=bigPhone") {
            font-size: .6rem;

          }
        }
        #img-react , #img-html, #img-css, #img-javascript{
          width: 35%;
          //height: 100%;
          @include media("<=bigPhone") {
            width: 50%;
          }
        }
        #img-express {
          width: 65%;
          @include media("<=bigPhone") {
            width: 85%;
          }
        }
      }
     }
    }
  }
}