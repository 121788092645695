@import '../../global.scss';
@import '../../responsive/breakpoints.scss';





.topbar {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $navbarColor;
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 2;
  animation: navbar-anim 500ms ease-in;

    .wrapper {
      position: relative;
      //background: red;
      height: 100%;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media("<=bigPhone") {
        width: 90%;
      }
      @include media(">=bigPhone", "<=tablet") {
        width: 80%;
      }
      @include media(">tablet","<LGdesktop") {
        width: 70%;
      }


      .left {
        position: absolute;
        left: 0;
        //background: chocolate;

        #logo {
          font-size: 40px;
          font-weight: 700;
          text-decoration: none;
          color: $titleColor;
        }
      }

      .right {
        height: auto;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: rgb(15, 72, 179);
        

        .nav-menu {
          position: absolute;
          right: 0;
          display: flex;
          justify-content: space-between;
          align-items:center;
          // background: yellowgreen;
          width: 50%;
          @include media("<=bigPhone") {
            display: none;
          }
          @include media(">=bigPhone", "<tablet") {
            display: none;
          }
          a {
            //background: palevioletred;
            margin: 0 0 0 0;
            font-size: 1.1em;
            font-weight: 600;
            text-decoration: none;
            color: $mainColor;
            @include media(">=bigPhone", "<=tablet") {
              font-size: 1em;
            }
            @include media(">=tablet","<LGdesktop") {
              font-size: .9rem;
            }
            &:hover {
              color: $specialColor;
            }
          }
        }
        .smallMenu {
          display: none;
          
        @include media("<tablet") {
          display: contents;
          position: absolute;
          right: 0;
          display: flex;
          justify-content: flex-end;
          align-items:center;
          font-size: 1.5rem;
            font-weight: 600;
            color: $mainColor;
          // background-color: rgb(184, 116, 116);
          }
        }

      }
    }    
}

@keyframes navbar-anim {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}